.spacer {
    flex: 1 1;
}

.cr-navbar {
    background: linear-gradient(#039BE5, #03a8f8);
    padding: 24px 48px;
    color: #fff;
   }

   .cr-navbar a {
    margin-left: 24px;
    color: white;
    opacity: 0.95;
    text-decoration: none;
    font-weight: bold;
   }

   .cr-navbar a:hover {
    opacity: 1;
    box-shadow: inset 0 -2px 0 0 rgb(3 226 152);
    padding-bottom: 5px;
   }

   .cr-navbar-logo-icon {
    width: 40px;
    margin-right: 8px;
   }

   .cr-navbar-logo {
    font-weight: bold;
    font-size: 24px;
    margin-right: 8px;
   }

   .cr-navbar-connect {
    /* background-color: #03E298; */

    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #03E298, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1800% 1800%;

    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;

    border: none;
    padding: 12px 16px;
    border-radius: 999px;
    font-weight: bold;
    color: white;
   }

   .cr-navbar-connect:hover {
    opacity: 0.85;
   }

   .cr-navbar-connect:active {
    background-color: #33e312;
    opacity: 0.8;
   }

   .cr-navbar-wallet-info {
    background-color: #252627;
    border-radius: 8px;
    padding: 8px 12px;
    margin-left: 20px;
    margin-right: 20px;
   }

   .cr-navbar-wallet-info-avatar {
    border-radius: 999px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    margin-right: 4px;
   }

   @media (max-width: 504px) {
    .cr-navbar a {
     font-size: 14px;
     margin-left: 12px;
    }
   }



@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
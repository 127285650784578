.cr-homescene {

}

.cr-homescene-banner {
    background: linear-gradient(#03a8f8, #039ae6);
    padding-top: 22px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    color: white;
}

.cr-homescene-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    /* text-shadow: 0px 0px 0px #b2a98f, 0px 14px 10px rgb(0 0 0 / 15%), 23px 18px 12px rgb(0 0 0 / 10%), 0px 24px 30px rgb(0 0 0 / 10%); */
}

.cr-homescene-subtitle {
    /*font-weight: bold;*/
    opacity: 0.87;
    line-height: 1.6;
    margin-bottom: 20px;
}

.cr-homescene-countdown {
    font-weight: bold;
    opacity: 0.87;
    line-height: 1.6;
    margin-top: 16px;
    font-size: 20px;
}

.cr-homescene-countdown-price {
    color: white;
    box-shadow: inset 0 -2px 0 0 rgb(3 226 152);
    padding-bottom: 5px;
}

.cr-homescene-countdown-time {
    color: rgb(3, 226, 152);
}

.cr-homescene-showroom {
    padding-top: 48px;
    padding-bottom: 64px;
}

.cr-homescene-marquee-img {
    width: 256px;
}

.cr-homescene-socials {
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
}

.cr-homescene-info-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
}

.cr-homescene-discord-link {
    color: #03E298;
    text-decoration: bold !important;
}
.cr-homescene-discord-link:hover {
    text-decoration: underline;
}

.cr-homescene-twitter-link {
    color: #03E298;
    text-decoration: bold !important;
}
.cr-homescene-twitter-link:hover {
    text-decoration: underline;
}

.cr-homescene-mint-input-container {
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    margin-right: 8px;
    border: none;
    padding: 4px 8px;
    background-color: white;
}

.cr-homescene-mint-input {
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    margin-right: 8px;
    border: none;
    padding: 4px 12px;
}

.cr-homescene-mint-disabled {
    background-color: #555;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: #888;
}

.cr-homescene-mint {
    background-color: #33e312;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
}

.cr-homescene-mint:hover {
    opacity: 0.92;
}

.cr-homescene-mint:active {
    opacity: 0.8;
}

.cr-homescene-connect {

    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #03E298, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1800% 1800%;

    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;

    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
}

.cr-homescene-connect:hover {
    opacity: 0.92;
}

.cr-homescene-connect:active {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .cr-homescene-title {
        font-size: 32px;
    }
    .cr-homescene-info-title {
        font-size: 32px;
    }
    .cr-homescene-marquee-img {
        width: 200px;
    }
}

.cr-homescene-splash-links {
    margin-left: 12px;
    margin-right: 12px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    vertical-align: middle;
    opacity: 1;
}

.cr-homescene-splash-links:hover {
    opacity: 0.9;
}

.cr-container {
    color: #333;
    margin: 0 auto;
    padding: 0.5rem;
    text-align: center;
}

.cr-li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
}

.cr-li span {
    display: block;
    font-size: 4.5rem;
}
